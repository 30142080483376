import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-title q-mb-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignUpHeader = _resolveComponent("SignUpHeader")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_SignUpContentHeader = _resolveComponent("SignUpContentHeader")!
  const _component_center = _resolveComponent("center")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createBlock(_component_q_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_SignUpHeader),
      (_ctx.emailSent)
        ? (_openBlock(), _createBlock(_component_q_page, {
            key: 0,
            class: "q-layout-container column justify-center items-center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('check_mail')), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_q_page, {
            key: 1,
            class: "q-layout-container column justify-center items-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_form, {
                onSubmit: _ctx.submit,
                class: "sign-form column"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SignUpContentHeader, { hideNav: "" }),
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_center, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('reset_password')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_q_btn, {
                    label: _ctx.$t('send_request'),
                    href: "https://t.me/gambeat_support",
                    target: "_blank",
                    color: "primary",
                    class: "q-btn__main-size text-weight-bold q-mb-lg",
                    unelevated: "",
                    "no-caps": ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_center, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('no_account')) + " ", 1),
                      _createVNode(_component_router_link, {
                        to: "/sign-up",
                        class: "col link-text"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('go_reg')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}