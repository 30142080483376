
import { defineComponent } from 'vue'
import { getGamblingColumns } from './config/columns'
import {
  request,
  Table,
  TableRequestProps,
  getTableProperties,
  getGroupByField,
  Row,
  FiltersType,
  getDefaultFiltersLast7Days,
} from '@/utils/request'
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import { getTotalValues } from '@/utils/getData'
import { exportToCsv } from '@/utils/export'
import { RewardType } from '@/enums/RewardType'
import { Vertical } from '@/enums/Vertical'
import { omitBy, isNull } from 'lodash'
import { formatValue } from '@/utils'
import i18n from '@/locales/i18n'
import { LocationQueryValue } from 'vue-router'
import { getData } from '@/utils/getData'
import { Currency } from '@/enums/Currency'
import ExpandableFilters from '@/components/filters/ExpandableFilters.vue'
import { PostbackEventType } from '@/enums/PostbackEventType'
import axios from 'axios'
import { heroOutline24ArrowDownTray } from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'GamblingTable',
  components: { ExpandableFilters },
  setup() {
    return {
      heroOutline24ArrowDownTray,
    }
  },
  computed: {
    baseCurrency(): string {
      if (this.dashboardTotal.baseCurrency === Currency.EUR) {
        return '€'
      }
      if (this.dashboardTotal.baseCurrency === Currency.UAH) {
        return '₴'
      }
      if (this.dashboardTotal.baseCurrency === Currency.PLN) {
        return 'zł'
      }
      return '$'
    },
    pagesNumber(): number {
      if (!this.rows.length) {
        return 0
      }
      return Math.ceil(
        (this.rows[0] as any).total / this.pagination.rowsPerPage,
      )
    },
    columns(): any {
      return getGamblingColumns()
    },
    getGroupByOptions(): any {
      return groupByOptions()
    },
    totalCrReg(): number | string {
      const value =
        Number(
          this.total('registrationCount', 'sum') /
            Number(this.total('uniqueClicks', 'sum')),
        ) * 100 || 0
      return formatValue(value === Infinity ? 0 : value)
    },
    totalCrFtd(): number | string {
      const value =
        Number(
          this.total('firstDepositCount', 'sum') /
            Number(this.total('uniqueClicks', 'sum')),
        ) * 100 || 0
      return formatValue(value === Infinity ? 0 : value)
    },
    totalCrFtdReg(): number | string {
      const value =
        Number(
          this.total('firstDepositCount', 'sum') /
            Number(this.total('registrationCount', 'sum')),
        ) * 100 || 0
      return formatValue(value === Infinity ? 0 : value)
    },
  },
  data: function() {
    const queryFilters = this.$route.query
    for (const [filter, value] of Object.entries(queryFilters)) {
      queryFilters[filter] = (Number(value) as unknown) as LocationQueryValue
    }
    let filters = { ...getDefaultFiltersLast7Days(), ...queryFilters }
    if (Object.keys(this.$route.params).length) {
      const paramsFilters = this.$route.params
      for (const [key, value] of Object.entries(paramsFilters)) {
        if (Number(value)) {
          paramsFilters[key] = (Number(value) as unknown) as string
        }
        if (key === 'factualStats') {
          if (typeof value === 'string') {
            paramsFilters[key] = ((value !== 'false') as unknown) as string
          } else {
            paramsFilters[key] = value
          }
        }
      }
      filters = { ...filters, ...paramsFilters }
    }
    return {
      paginationPage: 1,
      dashboardTotal: {
        baseCurrency: 0,
        clicks: 0,
        clicksDelta: 0,
        uniqueClicks: 0,
        uniqueClicksDelta: 0,
        registrationCount: 0,
        registrationCountDelta: 0,
        firstDepositCount: 0,
        firstDepositCountDelta: 0,
        profit: 0,
        offersCount: 0,
      },
      eventTypes: PostbackEventType,
      currencies: Currency,
      isExportChartData: false,
      chartData: [],
      chartLoading: true,
      selectedColumns: [
        'uniqueClicks',
        'registrationCount',
        'firstDepositCount',
      ],
      exportCsv: false,
      filters,
      chartDateFrom: filters.dateFrom,
      chartDateTo: filters.dateTo,
      rewardType: RewardType,
      vertical: Vertical,
      offers: {},
      landings: {},
      campaigns: {},
      geos: {},
      geosNames: {},
      ...getTableProperties('groupByField', 30),
    }
  },
  mounted() {
    this.lookupLists()
    this.onRequest({ pagination: this.pagination })
    this.onRequestDashboard()
  },
  watch: {
    paginationPage() {
      this.pagination.page = this.paginationPage
      this.onRequest({ pagination: this.pagination })
    },
    filters() {
      this.pagination.page = 1
      this.paginationPage = 1
      this.onRequest({ pagination: this.pagination })
      this.onRequestDashboard()
      this.onRequestChartData()
    },
    exportCsv() {
      this.exportTable()
    },
  },
  methods: {
    selectAllPagination() {
      this.pagination.page = 1
      this.paginationPage = 1
      this.pagination.rowsPerPage = this.rows?.length
        ? (this.rows[0] as any).total
        : 0
      this.onRequest({ pagination: this.pagination })
    },
    total(field: string, operation: 'sum' | 'avg'): number {
      return getTotalValues(this.rows, field, operation) as number
    },
    getGroupedField(field: 'id' | 'name' | 'field', row: Row) {
      return getGroupByField(field, row, this.getGroupByOptions, this.filters)
    },
    async onRequestChartData() {
      this.chartLoading = true
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      const filters = {
        ...(this.filters || {}),
        dateFrom: this.chartDateFrom,
        dateTo: this.chartDateTo,
      }
      this.chartData = await getData(
        { filters, limit: 0, offset: 0, orderBy },
        '/api/statistics/general2',
      )
      this.chartLoading = false
    },
    async onRequestDashboard() {
      const orderBy = {
        [String(this.filters?.groupBy)]: this.pagination.descending
          ? 'DESC'
          : 'ASC',
      }
      this.dashboardTotal = await getData(
        { filters: this.filters || {}, limit: 0, offset: 0, orderBy },
        '/api/statistics/dashboard',
      )
      const user = JSON.parse(localStorage.getItem('user') as string)
      this.dashboardTotal.baseCurrency = user?.data?.baseCurrency || 0
    },
    onRequest(props: TableRequestProps) {
      request(props, this as Table, '/api/statistics/general2')
    },
    exportTable() {
      const totalRow = {
        groupByField: i18n.t('total'),
        clicks: this.total('clicks', 'sum'),
        uniqueClicks: this.total('uniqueClicks', 'sum'),
        registrationCount: this.total('registrationCount', 'sum'),
        firstDepositCount: this.total('firstDepositCount', 'sum'),
        firstDepositSum: this.total('firstDepositSum', 'sum'),
        // depositCount: this.total('depositCount', 'sum'),
        // depositSum: this.total('depositSum', 'sum'),
        // uniqueDepositCount: this.total('uniqueDepositCount', 'sum'),
        crReg: this.total('crReg', 'avg'),
        crFtd: this.total('crFtd', 'avg'),
        crFtdReg: this.total('crFtdReg', 'avg'),
        approvedSum: this.total('approvedSum', 'sum'),
        holdSum: this.total('holdSum', 'sum'),
        rejectedSum: this.total('rejectedSum', 'sum'),
        revShareRevenueSum: this.total('revShareRevenueSum', 'sum'),
      }
      exportToCsv(
        this.rows,
        this.columns,
        totalRow as any,
        this.getGroupedField,
      )
    },
    detailConversions(params: any) {
      const ftdObj: any = {}
      if (
        params.eventType === PostbackEventType.FirstDeposit &&
        (this.filters.factualStats === true ||
          (this.filters as any).factualStats === 'true')
      ) {
        ftdObj.conversionDateFrom = params.week || this.filters.dateFrom
        ftdObj.conversionDateTo = params.week || this.filters.dateTo
      }
      const filters = omitBy(this.filters, isNull)
      this.$router.push({
        name: 'conversion',
        params: {
          ...filters,
          filters: JSON.stringify(filters),
          vertical: Vertical.Gambling,
          ...params,
          ...ftdObj,
        },
      })
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = { ...getDefaultFiltersLast7Days() }
      this.chartDateFrom = this.filters.dateFrom
      this.chartDateTo = this.filters.dateTo
    },
    onExport() {
      this.exportCsv = !this.exportCsv
    },
    getGroupByArray() {
      return this.getGroupByOptions
    },
    exportChartData() {
      this.isExportChartData = !this.isExportChartData
    },
    onChangeChartDate(date: { startDate: string; endDate: string }) {
      if (
        date.startDate === this.chartDateFrom &&
        date.endDate === this.chartDateTo
      ) {
        return
      }
      this.chartDateFrom = date.startDate
      this.chartDateTo = date.endDate
      this.onRequestChartData()
    },
    async lookupLists() {
      const data = (await axios.get('/api/statistics/lists')).data
      this.offers = data.offers.reduce((obj: any, key: any) => {
        const offerName = key.name.replace(/\s*\(.*?\)/, '')
        return { ...obj, [key.id]: offerName }
      }, {})
      this.campaigns = data.campaigns.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.landings = data.promos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
      this.geos = data.geos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.iso_code }),
        {},
      )
      this.geosNames = data.geos.reduce(
        (obj: any, key: any) => ({ ...obj, [key.id]: key.name }),
        {},
      )
    },
  },
})
