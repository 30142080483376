
import { defineComponent } from 'vue'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getPayoutColumns } from '@/pages/payments/config/columns'
import { Currency } from '@/enums/Currency'
import axios from 'axios'
import { BalanceType } from '@/enums/BalanceType'
import { Balance } from '@/store/types'
import { formatDate, formatValue, getSortedBalances } from '@/utils'
import { PayoutStatus } from '@/enums/PayoutStatus'
import { exportToCsv } from '@/utils/export'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'
import PayoutLeadsDialog from '@/components/dialogs/PayoutLeadsDialog.vue'

export default defineComponent({
  name: 'Payouts',
  components: { PayoutLeadsDialog },
  computed: {
    columns(): any {
      return getPayoutColumns()
    },
    availableBalances(): Balance[] {
      return this.sortedBalances.map(arr => arr[0])
    },
    isPayoutDisabled(): boolean {
      return !this.availableBalances.filter(b => b.value.amount).length
    },
  },
  watch: {
    isPayoutDisabled(newValue) {
      this.$emit('payoutDisabledStatusChanged', newValue)
    },
  },
  async created() {
    this.refresh()
  },
  data: function() {
    return {
      ...getTableProperties('createdAt'),
      currencies: Currency,
      payoutStatuses: PayoutStatus,
      paymentSystem: PaymentSystem,
      paymentSystemNetwork: PaymentSystemNetwork,
      sortedBalances: [
        [
          {
            value: { amount: 0, currency: Currency.USD },
            type: BalanceType.Available,
          },
          {
            value: { amount: 0, currency: Currency.USD },
            type: BalanceType.Hold,
          },
        ],
      ],
      payoutId: null as number | null,
      showPayoutLeadsDialog: false,
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      const options = props || { pagination: this.pagination }
      await request(options, this as Table, '/api/payouts/')
    },
    showPayoutLeads(payoutId: number) {
      this.payoutId = payoutId
      this.showPayoutLeadsDialog = !this.showPayoutLeadsDialog
    },
    getFormattedValue(value: number) {
      return formatValue(value)
    },
    async refresh() {
      const balances = (await axios.get('/api/balances')).data
      if (balances.length) {
        this.sortedBalances = getSortedBalances(balances)
      }
      await this.onRequest({ pagination: this.pagination })
      this.$emit('onRefresh', {
        isPayoutDisabled: this.isPayoutDisabled,
        refresh: this.refresh,
      })
    },
    exportCsv() {
      exportToCsv(this.rows, this.columns, undefined, undefined, 'payouts')
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
